/* eslint-disable import/no-unresolved */
import React from "react";
import { Container } from "react-bootstrap";
import { GatsbyImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const Testimonials = ({ slice }) => {
  const testimonials = slice.items;
  return (
    <Container className="testimonials-container my-5">
      <div id="testimonials">
        <h2 className="pb-4 ps-3 ps-sm-0">{slice.primary.title}</h2>
        <Swiper
          spaceBetween={10}
          slidesPerView={1}
          pagination={{
            clickable: true,
            dynamicBullets: true,
            dynamicMainBullets: "3",
          }}
          autoplay={{
            delay: 4000,
            disableOnInteraction: true,
          }}
          modules={[Pagination, Autoplay]}
          breakpoints={{
            992: {
              slidesPerView: 2,
              slidesPerGroup: 2,
            },
          }}
        >
          {testimonials.map((testimonial, i) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <SwiperSlide key={i}>
                <GatsbyImage
                  image={testimonial.testimonial_image.gatsbyImageData}
                  alt={testimonial.testimonial_image.alt || "testimonial"}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </Container>
  );
};

export default Testimonials;
